import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { ImFacebook, ImTwitter, ImLink } from "react-icons/im";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { Helmet } from "react-helmet";
import { graphql, withPrefix } from "gatsby";
import { Box, Icon, useMediaQuery, Flex } from "@chakra-ui/react";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
  date,
  location,
  featuredImage,
}) => {
  const PostContent = contentComponent || Content;
  const [isBig] = useMediaQuery("(min-width: 1024px)");
  const url = location.href || "";

  useEffect(() => {
    function shareScroll() {
      const currentScrollPos = window.pageYOffset;

      if (document.getElementById("share-comp")) {
        if (currentScrollPos < 420) {
          document.getElementById("share-comp").style.right = "-80px";
        } else {
          document.getElementById("share-comp").style.right = "0";
        }
      }
    }

    window.addEventListener("scroll", shareScroll);

    return () => window.removeEventListener("scroll", shareScroll);
  }, []);

  return (
    <section>
      {helmet || ""}
      <Box position="relative" mb={24}>
        <Box height={isBig ? "120px" : "78px"} width="100%" bg="black"></Box>
        <Box className="share-comp" id="share-comp">
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <FacebookShareButton url={url}>
              <Icon
                color="white"
                fontSize="24px"
                as={ImFacebook}
                cursor="pointer"
              />
            </FacebookShareButton>
            <TwitterShareButton url={url}>
              <Icon
                mt={6}
                color="white"
                fontSize="24px"
                as={ImTwitter}
                cursor="pointer"
              />
            </TwitterShareButton>
            <Icon
              mt={6}
              color="white"
              fontSize="24px"
              mb="4px"
              as={ImLink}
              cursor="pointer"
            />
          </Flex>
        </Box>
        <Box mb={24} backgroundColor="black">
          <Box pb={14} mb={8} className="wrapper-lg" backgroundColor="black">
            <Box
              height="55vh"
              width="100%"
              backgroundImage={`url(${featuredImage})`}
              backgroundSize="cover"
              padding="40px"
              display="flex"
              flexDir="column"
              justifyContent="flex-end"
              backgroundPosition="center"
              mb={4}
            />
            <Flex
              flexDir={!isBig && "column"}
              justify="space-between"
              alignItems={isBig ? "flex-end" : "flex-start"}
            >
              <Box mb={!isBig && 8}>
                <h1 className="sc-title-two color-white">{title}</h1>
                <p className="sc-subtitle-two color-white">{date}</p>
              </Box>
              <Flex>
                <FacebookShareButton url={url}>
                  <Icon
                    color="white"
                    fontSize="24px"
                    mb="4px"
                    as={ImFacebook}
                    cursor="pointer"
                  />
                </FacebookShareButton>
                <TwitterShareButton url={url}>
                  <Icon
                    ml={4}
                    color="white"
                    fontSize="24px"
                    mb="4px"
                    as={ImTwitter}
                    cursor="pointer"
                  />
                </TwitterShareButton>
                <Icon
                  ml={4}
                  color="white"
                  fontSize="24px"
                  mb="4px"
                  as={ImLink}
                  cursor="pointer"
                />
              </Flex>
            </Flex>
          </Box>
        </Box>
        <div className="wrapper-sm">
          {/* <p className="sc-description">{description}</p> */}
          <PostContent
            className="blog-content sc-description"
            content={content}
          />
        </div>
      </Box>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data, location }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout location={location}>
      <BlogPostTemplate
        location={location}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              property="og:title"
              name="og:title"
              content={`${post.frontmatter.title}`}
            />
            <meta
              property="og:description"
              name="description"
              content={`${post.frontmatter.description}`}
            />
            <meta
              property="og:image"
              content={`${withPrefix("")}${
                post.frontmatter.featuredimage
                  ? post.frontmatter.featuredimage.childImageSharp.fluid.src
                  : ""
              }`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        date={post.frontmatter.date}
        featuredImage={
          post.frontmatter.featuredimage
            ? post.frontmatter.featuredimage.childImageSharp.fluid.src
            : ""
        }
      />
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        featuredimage {
          childImageSharp {
            fluid(quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
